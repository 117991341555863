$('#banner').owlCarousel({
	singleItem: true,
	autoPlay: true,
	navigation: true,
	navigationText: [
		'<i class="icon prev-banner"></i>',
		'<i class="icon next-banner"></i>'
	]
})

$('#avalicoes').owlCarousel({
	autoPlay: true,
	singleItem: true
});