// EFEITO DOS INPUTS
$('.field-effect .form-control').focus(function(){
	$(this).parents('.form-group').addClass('focus');
});

$('.field-effect .form-control').blur(function(){
	var v = $(this).val();

	if (v == ''){
		$(this).parents('.form-group').removeClass('focus');
	} else{
		$(this).parents('.form-group').addClass('has-success')
		return false;
	}
});

$('[name="endereco-de-entrega"]').focus(function(event) {
	var id = $(this).attr('id');

	switch (id){
		case 'sim':
			$('#enderecoAlternativo').fadeOut();
		break;
		case 'nao':
			$('#enderecoAlternativo').fadeIn();
		break;
	}
});

// ACRESCENTAR | REMOVER PRODUTO DO CARRINHO
$('[data-calc-qnt="plus"]').click(function(){
	var a = $(this).data('target');
	var v1 = parseInt($(a).val());
	var v2 = v1 + 1;

	$(a).val(v2);
});

$('[data-calc-qnt="minus"]').click(function(){
	var a = $(this).data('target');
	var v1 = parseInt($(a).val());
	var v2 = v1 - 1;

	if (v1 > 1){
		$(a).val(v2);
	}else{
		return false;
	}
});

$('[data-toggle="portifolio"]').click(function(event) {
	var a = $(this).data('target');

	$('[data-toggle="portifolio"]').removeClass('active');
	$(this).addClass('active');

	$(this).parents('.portifolio').find('.section').removeClass('active');
	$(a).addClass('active');
});


// ESTRELAS
$('[data-toggle="stars"]').click(function(){
	var v = $(this).data('value');
	var t = $(this).data('target');

	$('.estrela-avaliacao').removeClass('active');
	$(this).addClass('active');
	$(t).val(v);
});

// CONFIGURANDO O ZOOM

$("#zoom_img").elevateZoom({
	zoomType: "inner",
	containLensZoom: true,
	gallery:'gallery_01',
	cursor: 'crosshair',
	galleryActiveClass: "active",
	responsive: true
});

//pass the images to Fancybox

$("#zoom_03").bind("click", function(e) { var ez = $('#zoom_03').data('elevateZoom');	$.fancybox(ez.getGalleryList()); return false; });

