$('[data-toggle="categorias"]').click(function(){
	var a = $(this).data('target');

	$(a).toggleClass('active');
})

$('#categorias-topo .container').click(function(event) {
	event.stopPropagation();
});

$(document).click(function(event) {
	$('#categorias-topo').removeClass('active');
});

$('[data-toggle="subcat"]').click(function(event) {
	var a = $(this).data('target');

	$(a).collapse('toggle')
});