var CoockiesController = (function() {
	var Storage = {};
	var HOJE    = hoje();
	var popup   = document.querySelector('.popup__cookies');
	var btn     = document.querySelector('.popup__cookies .btn');

	function CoockiesController() {
		createProxy(Storage);

		var cookies = Storage.cookies;

		if(!popup) return false;

		if(!cookies) {
			showPopup();
		}

		else {
			if(HOJE === cookies.data) {
				removePopup()
			}

			else {
				showPopup();
			}
		}
	}

	function showPopup() {
		popup.classList.add('popup--shown');
		
		btn.addEventListener('click', function(e) {
			e.preventDefault();

			save()
			hidePopup();
		});
	}

	function hidePopup() {
		popup.classList.add('popup--hide');

		popup.addEventListener('animationend', function() {
			removePopup();
		})
	}

	function removePopup() {
		popup.insertAdjacentHTML('afterend', '<!---->');
		popup.parentNode.removeChild(popup);
	}

	function save() {
		Storage.cookies = {
			data   : HOJE,
			aceito : new Date()
		};
	}

	// Pegando e formatando a data de HOJE
	function hoje() {
		var data = new Date();
		var dia  = data.getDate();
		var mes  = data.getMonth() + 1;
		var ano  = data.getFullYear();

		mes = mes < 10 ? '0'+mes : mes;

		return dia+'/'+mes+'/'+ano;
	}

	// Função que cria o proxy responsavel por gerenciar o localStorage
	function createProxy(obj) {
		Object.defineProperty(Storage, 'cookies', {
			set(value) {
				localStorage.setItem(
					'cookies', 
					JSON.stringify(value)
				);
			},
			get() {
				return JSON.parse(
					localStorage.getItem('cookies')
				);
			}
		})
	}

	return CoockiesController();
})();