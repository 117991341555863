function destaques(){
	var carousel = $('#destaques');
	var carsouselInner  = $('#destaques .carousel-inner');
	var items = carsouselInner.html();
	var qntItens = carousel.find('.col-sm-6').length;

	function sliceOnResize(count) {
		carsouselInner.html('');
		carsouselInner.append(items);

		for (var i=0;i < qntItens; i += count){
			carousel.find('.col-sm-6').slice(i,i+count).wrapAll('<div class="item row"></div>');
		}

		carousel.find('.item:first-child').addClass('active');
	}

	function onResize(){
		var w = $(window).width();
		var m = 'xs';

		if (w > 700){
			m = 'sm';
		}

		if (w > 900){
			m = 'md';
		}

		switch (m){
			case 'md':
				sliceOnResize(3);
			break;

			case 'sm':
				sliceOnResize(2);
			break;

			case 'xs':
				sliceOnResize(1);
			break;

			default:
				sliceOnResize(1);
			break;
		}
	}

	onResize();

	$(window).resize(function(){
		onResize()
	})
};

destaques();

$('#destaques').carousel({interval:0});